import React from "react";
import FacilityPermission from "sections/Admin/FacilityPermission/FacilityPermission";

const FacilityPermissionPage = () => {
  return (
    <>
      <FacilityPermission />
    </>
  );
};

export default FacilityPermissionPage;
