import React from "react";
import FacilityDetails from "sections/Homepage/FacilityDetails";

const FacilityDetailsPage = () => {
  return (
    <>
      <FacilityDetails />
    </>
  );
};

export default FacilityDetailsPage;
