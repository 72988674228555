import React from "react";
import CompanyListing from "sections/Admin/CompanyManagement/CompanyListing";

const CompanyList = () => {
  return (
    <>
      <CompanyListing />
    </>
  );
};

export default CompanyList;
