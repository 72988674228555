import { entriesEndPoints, meterEndPoints } from "constants/apiEndPoints";

import { GET_REQUEST } from "utils/HTTPRequests";
import {
  fetchEntriesListFailure,
  fetchEntriesListRequest,
  fetchEntriesListSuccess,
} from "../actionCreators/entriesActionCreators";
import NotificationsToast from "../../../utils/notification/NotificationsToast.js";

export const fetchEntriesListing = (pageInfo, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchEntriesListRequest());
      const endpointWithParams = `${entriesEndPoints.ENTRIES_LIST}/${
        (pageInfo.page - 1) * pageInfo.pageSize
      }/${pageInfo.pageSize}?facility_meter_detail_id=${id}`;
      const response = await GET_REQUEST(endpointWithParams);
      const data = response.data;
      dispatch(fetchEntriesListSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(fetchEntriesListFailure(error));
      NotificationsToast({
        message: error?.message ? error.message : "Something went wrong!",
        type: "error",
      });
    }
  };
};
