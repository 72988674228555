//---------------------------------FACILITY TYPES--------------------------------

export const FETCH_FACILITY_LIST_REQUEST = "FETCH_FACILITY_LIST_REQUEST";
export const FETCH_FACILITY_LIST_SUCCESS = "FETCH_FACILITY_LIST_SUCCESS";
export const FETCH_FACILITY_LIST_FAILURE = "FETCH_FACILITY_LIST_FAILURE";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const SUBMIT_FACILITY_FOR_APPROVAL_REQUEST =
  "SUBMIT_FACILITY_FOR_APPROVAL_REQUEST";
export const SUBMIT_FACILITY_FOR_APPROVAL_SUCCESS =
  "SUBMIT_FACILITY_FOR_APPROVAL_SUCCESS";
export const SUBMIT_FACILITY_FOR_APPROVAL_FAILURE =
  "SUBMIT_FACILITY_FOR_APPROVAL_FAILURE";

export const FETCH_FACILITY_DETAILS_REQUEST = "FETCH_FACILITY_DETAILS_REQUEST";
export const FETCH_FACILITY_DETAILS_SUCCESS = "FETCH_FACILITY_DETAILS_SUCCESS";
export const FETCH_FACILITY_DETAILS_FAILURE = "FETCH_FACILITY_DETAILS_FAILURE";

export const DELETE_FACILITY_REQUEST = "DELETE_FACILITY_REQUEST";
export const DELETE_FACILITY_SUCCESS = "DELETE_FACILITY_SUCCESS";
export const DELETE_FACILITY_FAILURE = "DELETE_FACILITY_FAILURE";

export const ADD_FACILITY_CHARACTERISTICS_REQUEST = "ADD_FACILITY_CHARACTERISTICS_REQUEST";
export const ADD_FACILITY_CHARACTERISTICS_SUCCESS = "ADD_FACILITY_CHARACTERISTICS_SUCCESS";
export const ADD_FACILITY_CHARACTERISTICS_FAILURE = "ADD_FACILITY_CHARACTERISTICS_FAILURE";

export const FETCH_FACILITY_CHARACTERISTICS_REQUEST = "FETCH_FACILITY_CHARACTERISTICS_REQUEST";
export const FETCH_FACILITY_CHARACTERISTICS_SUCCESS = "FETCH_FACILITY_CHARACTERISTICS_SUCCESS";
export const FETCH_FACILITY_CHARACTERISTICS_FAILURE = "FETCH_FACILITY_CHARACTERISTICS_FAILURE";

export const UPDATE_FACILITY_CHARACTERISTICS_REQUEST = "UPDATE_FACILITY_CHARACTERISTICS_REQUEST";
export const UPDATE_FACILITY_CHARACTERISTICS_SUCCESS = "UPDATE_FACILITY_CHARACTERISTICS_SUCCESS";
export const UPDATE_FACILITY_CHARACTERISTICS_FAILURE = "UPDATE_FACILITY_CHARACTERISTICS_FAILURE";

export const FETCH_FACILITY_STATUS_REQUEST = "FETCH_FACILITY_STATUS_REQUEST";
export const FETCH_FACILITY_STATUS_SUCCESS = "FETCH_FACILITY_STATUS_SUCCESS";
export const FETCH_FACILITY_STATUS_FAILURE = "FETCH_FACILITY_STATUS_FAILURE";

export const UPDATE_FACILITY_STATUS_REQUEST = "UPDATE_FACILITY_STATUS_REQUEST";
export const UPDATE_FACILITY_STATUS_SUCCESS = "UPDATE_FACILITY_STATUS_SUCCESS";
export const UPDATE_FACILITY_STATUS_FAILURE = "UPDATE_FACILITY_STATUS_FAILURE";

export const ASSIGN_FACILITIES_REQUEST = "ASSIGN_FACILITIES_REQUEST";
export const ASSIGN_FACILITIES_SUCCESS = "ASSIGN_FACILITIES_SUCCESS";
export const ASSIGN_FACILITIES_FAILURE = "ASSIGN_FACILITIES_FAILURE";

export const FETCH_FACILITIES_DROPDOWN_REQUEST = "FETCH_FACILITIES_DROPDOWN_REQUEST";
export const FETCH_FACILITIES_DROPDOWN_SUCCESS = "FETCH_FACILITIES_DROPDOWN_SUCCESS";
export const FETCH_FACILITIES_DROPDOWN_FAILURE = "FETCH_FACILITIES_DROPDOWN_FAILURE";

//---------------------------------METER TYPES--------------------------------
export const FETCH_METER_LIST_REQUEST = "FETCH_METER_LIST_REQUEST";
export const FETCH_METER_LIST_SUCCESS = "FETCH_METER_LIST_SUCCESS";
export const FETCH_METER_LIST_FAILURE = "FETCH_METER_LIST_FAILURE";

export const ADD_METER_REQUEST = "ADD_METER_REQUEST";
export const ADD_METER_SUCCESS = "ADD_METER_SUCCESS";
export const ADD_METER_FAILURE = "ADD_METER_FAILURE";

export const UPDATE_METER_REQUEST = "UPDATE_METER_REQUEST";
export const UPDATE_METER_SUCCESS = "UPDATE_METER_SUCCESS";
export const UPDATE_METER_FAILURE = "UPDATE_METER_FAILURE";

export const FETCH_METER_DETAILS_REQUEST = "FETCH_METER_DETAILS_REQUEST";
export const FETCH_METER_DETAILS_SUCCESS = "FETCH_METER_DETAILS_SUCCESS";
export const FETCH_METER_DETAILS_FAILURE = "FETCH_METER_DETAILS_FAILURE";

export const DELETE_METER_REQUEST = "DELETE_METER_REQUEST";
export const DELETE_METER_SUCCESS = "DELETE_METER_SUCCESS";
export const DELETE_METER_FAILURE = "DELETE_METER_FAILURE";

export const FETCH_METER_STATISTICS_REQUEST = "FETCH_METER_STATISTICS_REQUEST";
export const FETCH_METER_STATISTICS_SUCCESS = "FETCH_METER_STATISTICS_SUCCESS";
export const FETCH_METER_STATISTICS_FAILURE = "FETCH_METER_STATISTICS_FAILURE";
//---------------------------------ENTRIES TYPES--------------------------------
export const FETCH_ENTRIES_LIST_REQUEST = "FETCH_ENTRIES_LIST_REQUEST";
export const FETCH_ENTRIES_LIST_SUCCESS = "FETCH_ENTRIES_LIST_SUCCESS";
export const FETCH_ENTRIES_LIST_FAILURE = "FETCH_ENTRIES_LIST_FAILURE";

export const SHOW_LOADER = "SHOW_LOADER";