//---------------------------------ADMIN FACILITY TYPES--------------------------------

export const FETCH_ADMIN_FACILITY_LIST_REQUEST = "FETCH_ADMIN_FACILITY_LIST_REQUEST";
export const FETCH_ADMIN_FACILITY_LIST_SUCCESS = "FETCH_ADMIN_FACILITY_LIST_SUCCESS";
export const FETCH_ADMIN_FACILITY_LIST_FAILURE = "FETCH_ADMIN_FACILITY_LIST_FAILURE";

export const FETCH_ADMIN_FACILITY_DETAILS_REQUEST = "FETCH_ADMIN_FACILITY_DETAILS_REQUEST";
export const FETCH_ADMIN_FACILITY_DETAILS_SUCCESS = "FETCH_ADMIN_FACILITY_DETAILS_SUCCESS";
export const FETCH_ADMIN_FACILITY_DETAILS_FAILURE = "FETCH_ADMIN_FACILITY_DETAILS_FAILURE";

export const DELETE_ADMIN_FACILITY_REQUEST = "DELETE_ADMIN_FACILITY_REQUEST";
export const DELETE_ADMIN_FACILITY_SUCCESS = "DELETE_ADMIN_FACILITY_SUCCESS";
export const DELETE_ADMIN_FACILITY_FAILURE = "DELETE_ADMIN_FACILITY_FAILURE";

export const ADD_ADMIN_FACILITY_CHARACTERISTICS_REQUEST = "ADD_ADMIN_FACILITY_CHARACTERISTICS_REQUEST";
export const ADD_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS = "ADD_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS";
export const ADD_ADMIN_FACILITY_CHARACTERISTICS_FAILURE = "ADD_ADMIN_FACILITY_CHARACTERISTICS_FAILURE";

export const FETCH_ADMIN_FACILITY_CHARACTERISTICS_REQUEST = "FETCH_ADMIN_FACILITY_CHARACTERISTICS_REQUEST";
export const FETCH_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS = "FETCH_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS";
export const FETCH_ADMIN_FACILITY_CHARACTERISTICS_FAILURE = "FETCH_ADMIN_FACILITY_CHARACTERISTICS_FAILURE";

export const UPDATE_ADMIN_FACILITY_CHARACTERISTICS_REQUEST = "UPDATE_ADMIN_FACILITY_CHARACTERISTICS_REQUEST";
export const UPDATE_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS = "UPDATE_ADMIN_FACILITY_CHARACTERISTICS_SUCCESS";
export const UPDATE_ADMIN_FACILITY_CHARACTERISTICS_FAILURE = "UPDATE_ADMIN_FACILITY_CHARACTERISTICS_FAILURE";

export const FETCH_ADMIN_FACILITY_STATUS_REQUEST = "FETCH_ADMIN_FACILITY_STATUS_REQUEST";
export const FETCH_ADMIN_FACILITY_STATUS_SUCCESS = "FETCH_ADMIN_FACILITY_STATUS_SUCCESS";
export const FETCH_ADMIN_FACILITY_STATUS_FAILURE = "FETCH_ADMIN_FACILITY_STATUS_FAILURE";

export const UPDATE_ADMIN_FACILITY_STATUS_REQUEST = "UPDATE_ADMIN_FACILITY_STATUS_REQUEST";
export const UPDATE_ADMIN_FACILITY_STATUS_SUCCESS = "UPDATE_ADMIN_FACILITY_STATUS_SUCCESS";
export const UPDATE_ADMIN_FACILITY_STATUS_FAILURE = "UPDATE_ADMIN_FACILITY_STATUS_FAILURE";

export const ADMIN_ASSIGN_FACILITIES_REQUEST = "ADMIN_ASSIGN_FACILITIES_REQUEST";
export const ADMIN_ASSIGN_FACILITIES_SUCCESS = "ADMIN_ASSIGN_FACILITIES_SUCCESS";
export const ADMIN_ASSIGN_FACILITIES_FAILURE = "ADMIN_ASSIGN_FACILITIES_FAILURE";

export const FETCH_ADMIN_FACILITIES_DROPDOWN_REQUEST = "FETCH_ADMIN_FACILITIES_DROPDOWN_REQUEST";
export const FETCH_ADMIN_FACILITIES_DROPDOWN_SUCCESS = "FETCH_ADMIN_FACILITIES_DROPDOWN_SUCCESS";
export const FETCH_ADMIN_FACILITIES_DROPDOWN_FAILURE = "FETCH_ADMIN_FACILITIES_DROPDOWN_FAILURE";

export const FETCH_ADMIN_STATISTICS_REQUEST = "FETCH_ADMIN_STATISTICS_REQUEST";
export const FETCH_ADMIN_STATISTICS_SUCCESS = "FETCH_ADMIN_STATISTICS_SUCCESS";
export const FETCH_ADMIN_STATISTICS_FAILURE = "FETCH_ADMIN_STATISTICS_FAILURE";

export const DOWNLOAD_FACILITIES_BULK_REQUEST = "DOWNLOAD_FACILITIES_BULK_REQUEST"
export const DOWNLOAD_FACILITIES_BULK_SUCCESS = "DOWNLOAD_FACILITIES_BULK_SUCCESS"
export const DOWNLOAD_FACILITIES_BULK_FAILURE = "DOWNLOAD_FACILITIES_BULK_FAILURE"

export const DOWNLOAD_FACILITY_ROW_REQUEST = "DOWNLOAD_FACILITY_ROW_REQUEST"
export const DOWNLOAD_FACILITY_ROW_SUCCESS = "DOWNLOAD_FACILITY_ROW_SUCCESS"
export const DOWNLOAD_FACILITY_ROW_FAILURE = "DOWNLOAD_FACILITY_ROW_FAILURE"
//---------------------------------ADMIN METER TYPES--------------------------------

export const FETCH_ADMIN_METER_LIST_REQUEST = "FETCH_ADMIN_METER_LIST_REQUEST";
export const FETCH_ADMIN_METER_LIST_SUCCESS = "FETCH_ADMIN_METER_LIST_SUCCESS";
export const FETCH_ADMIN_METER_LIST_FAILURE = "FETCH_ADMIN_METER_LIST_FAILURE";

export const ADD_ADMIN_METER_REQUEST = "ADD_ADMIN_METER_REQUEST";
export const ADD_ADMIN_METER_SUCCESS = "ADD_ADMIN_METER_SUCCESS";
export const ADD_ADMIN_METER_FAILURE = "ADD_ADMIN_METER_FAILURE";

export const UPDATE_ADMIN_METER_REQUEST = "UPDATE_ADMIN_METER_REQUEST";
export const UPDATE_ADMIN_METER_SUCCESS = "UPDATE_ADMIN_METER_SUCCESS";
export const UPDATE_ADMIN_METER_FAILURE = "UPDATE_ADMIN_METER_FAILURE";

export const FETCH_ADMIN_METER_DETAILS_REQUEST = "FETCH_ADMIN_METER_DETAILS_REQUEST";
export const FETCH_ADMIN_METER_DETAILS_SUCCESS = "FETCH_ADMIN_METER_DETAILS_SUCCESS";
export const FETCH_ADMIN_METER_DETAILS_FAILURE = "FETCH_ADMIN_METER_DETAILS_FAILURE";

export const DELETE_ADMIN_METER_REQUEST = "DELETE_ADMIN_METER_REQUEST";
export const DELETE_ADMIN_METER_SUCCESS = "DELETE_ADMIN_METER_SUCCESS";
export const DELETE_ADMIN_METER_FAILURE = "DELETE_ADMIN_METER_FAILURE";

export const FETCH_ADMIN_METER_STATISTICS_REQUEST = "FETCH_ADMIN_METER_STATISTICS_REQUEST";
export const FETCH_ADMIN_METER_STATISTICS_SUCCESS = "FETCH_ADMIN_METER_STATISTICS_SUCCESS";
export const FETCH_ADMIN_METER_STATISTICS_FAILURE = "FETCH_ADMIN_METER_STATISTICS_FAILURE";

//------------------------------------ADMIN ENTRIES TYPES--------------------------------

export const FETCH_ADMIN_ENTRIES_LIST_REQUEST = "FETCH_ADMIN_ENTRIES_LIST_REQUEST";
export const FETCH_ADMIN_ENTRIES_LIST_SUCCESS = "FETCH_ADMIN_ENTRIES_LIST_SUCCESS";
export const FETCH_ADMIN_ENTRIES_LIST_FAILURE = "FETCH_ADMIN_ENTRIES_LIST_FAILURE";

//------------------------------------ADMIN COMPANY TYPES--------------------------------

export const FETCH_ADMIN_COMPANY_LIST_REQUEST = "FETCH_ADMIN_COMPANY_LIST_REQUEST";
export const FETCH_ADMIN_COMPANY_LIST_SUCCESS = "FETCH_ADMIN_COMPANY_LIST_SUCCESS";
export const FETCH_ADMIN_COMPANY_LIST_FAILURE = "FETCH_ADMIN_COMPANY_LIST_FAILURE";

export const FETCH_ADMIN_COMPANY_DETAILS_REQUEST = "FETCH_ADMIN_COMPANY_DETAILS_REQUEST";
export const FETCH_ADMIN_COMPANY_DETAILS_SUCCESS = "FETCH_ADMIN_COMPANY_DETAILS_SUCCESS";
export const FETCH_ADMIN_COMPANY_DETAILS_FAILURE = "FETCH_ADMIN_COMPANY_DETAILS_FAILURE";

export const ADMIN_COMPANY_SEND_ALERT_REQUEST = "ADMIN_COMPANY_SEND_ALERT_REQUEST";
export const ADMIN_COMPANY_SEND_ALERT_SUCCESS = "ADMIN_COMPANY_SEND_ALERT_SUCCESS";
export const ADMIN_COMPANY_SEND_ALERT_FAILURE = "ADMIN_COMPANY_SEND_ALERT_FAILURE";

export const ADMIN_COMPANY_UPDATE_STATUS_REQUEST = "ADMIN_COMPANY_UPDATE_STATUS_REQUEST";
export const ADMIN_COMPANY_UPDATE_STATUS_SUCCESS = "ADMIN_COMPANY_UPDATE_STATUS_SUCCESS";
export const ADMIN_COMPANY_UPDATE_STATUS_FAILURE = "ADMIN_COMPANY_UPDATE_STATUS_FAILURE";

export const FETCH_ADMIN_COMPANIES_DROPDOWN_REQUEST = "FETCH_ADMIN_COMPANIES_DROPDOWN_REQUEST";
export const FETCH_ADMIN_COMPANIES_DROPDOWN_SUCCESS = "FETCH_ADMIN_COMPANIES_DROPDOWN_SUCCESS";
export const FETCH_ADMIN_COMPANIES_DROPDOWN_FAILURE = "FETCH_ADMIN_COMPANIES_DROPDOWN_FAILURE";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE"