const userTypes = [
    {
        id: 1,
        userType: 'Enerva',
    },
    {
        id: 4,
        userType: 'IESO',
    },
    {
        id: 2,
        userType: 'Customer',
    },
    {
        id: 5,
        userType: 'Aggregator',
    },
]

export {userTypes};

export const dateFilterTypes = [
  {
    id: 1,
    dateFilterType: "Today",
  },
  {
    id: 2,
    dateFilterType: "Past 01 Week",
  },
  {
    id: 3,
    dateFilterType: "Past 30 Days",
  },
  {
    id: 4,
    dateFilterType: "Past 60 Days",
  },
  {
    id: 5,
    dateFilterType: "Custom Date",
  },
];