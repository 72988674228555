import React from "react";
import AdminFacilityListing from "sections/Admin/AdminFacilityListing";

const AdminFacilityList = () => {
  return (
    <>
      <AdminFacilityListing />
    </>
  );
};

export default AdminFacilityList;
