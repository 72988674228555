import React from "react";
import Facility from "sections/Homepage/FacilitySection";

const FacilityList = () => {
  return (
    <>
      <Facility />
    </>
  );
};

export default FacilityList;
