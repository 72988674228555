import React from "react";
import AddFacility from "sections/Homepage/AddFacility";

const AddFacilityComponent = () => {
  return (
    <>
      <AddFacility />
    </>
  );
};

export default AddFacilityComponent;
