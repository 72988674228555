import { styled } from "@mui/material";
import InputField from "./InputField";

const CustomPhoneInput = styled(InputField)(() => {
  return {
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  };
});

export default CustomPhoneInput;
