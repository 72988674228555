import React from "react";
import AddFacility from "sections/Homepage/AddFacility";
import DashboardSection from "sections/Homepage/DashboardSection";

const DashboardSectionComponent = () => {
  return (
    <>
      <DashboardSection />
    </>
  );
};

export default DashboardSectionComponent;
