import React from "react";
import CompanyProfile from "sections/Admin/CompanyManagement/CompanyProfile";

const CompanyProfilePage = () => {
  return (
    <>
      <CompanyProfile />
    </>
  );
};

export default CompanyProfilePage;
