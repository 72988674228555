import React from "react";
import AdminFacilityDetails from "sections/Admin/AdminFacilityDetails";

const AdminFacilityDetailsPage = () => {
  return (
    <>
      <AdminFacilityDetails />
    </>
  );
};

export default AdminFacilityDetailsPage;
